// react + dependencies
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// components
import GalleryComponent from '../components/GalleryComponent';
// local api for gallery categories
import images from '../api/galleryImages';

const Galerie = () => {
  // State to manage the selected category and filtered images
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredImages, setFilteredImages] = useState([]);

  // Function to check if the device is a mobile or tablet
  // const isMobileOrTablet = () => {
  //  return window.innerWidth <= 768; // Adjust the threshold as needed for tablets
  // };

  // Function to handle category change
  const handleCategoryChange = (category) => {
    // Set the selected category
    setSelectedCategory(category);

    // Scroll to the filtered div
    const filteredDiv = document.getElementById('filtered');
    if (filteredDiv) {
      filteredDiv.scrollIntoView({ behavior: 'smooth' });
    }

    // Filter images based on category
    const newFilteredImages = category ? images.filter(image => image.category === category) : [];
    // Set the filtered images based on the selected category
    setFilteredImages(newFilteredImages);
  };


  return (
    <>
      <Helmet>
        <title>Kavalier Photographer - Galerie</title>
        <meta name="description" content="Rodinná a reportážní fotografka z Českého Ráje. Nefotím nucené pózy, ale čisté emoce, radost, lásku a rodinnou pohodu." />
      </Helmet>

      <section className='w-full my-0'>
        <div className='w-full'>
          <div className='h-full container'> {/* flex flex-col md:flex-row items-start justify-between */}

            {/* left - now full width due to commented classes */}
            <div className='w-full pt-0 md:pt-6 '> {/* md:w-4/12 relative md:sticky top-0 md:h-screen md:overflow-auto */}
              <div className='pl-0 mr-0 pt-6 md:pt-10 border-t border-[var(--cerna)]'>
                <div>
                  <h3 className="text-3xl font-bold mb-4">galerie</h3>
                  <p>Focení probíhá na předem domluveném místě, nejčastěji v Českém Ráji, ale není problém dojet za Vámi domů. Po předchozí domluvě Vám mohu pomoci se stylingem. Pohodlnost je nutností. Půjdeme se projít do přírody, pokecáme o životě, o svých trapasech i snech. Bezprostředně po focení zasílám náhledy dle vlastního výběru. Finální fotografie zasílám přes úschovnu a sdílím do soukromé elektronické galerie.</p>
                </div>
              </div>
            </div>

            {/* right */}
            <div className="w-full mt-6 md:mt-0 mb-20"> {/* md:w-8/12 */}
              <div className='w-full m-auto'> {/* md:w-10/12 */}
                <div className="mt-4 mb-0 md:mb-4 flex flex-wrap gap-2 pb-0 md:pb-20 justify-between" id='btn-filters'>
                  {/*<button onClick={() => handleCategoryChange(null)} className={`btn-vse ${selectedCategory === null ? 'active' : ''}`}><p>Vše</p></button>*/}
                  <button onClick={() => handleCategoryChange('rodina')} className={`btn-rodina ${selectedCategory === 'rodina' ? 'active' : ''}`}><p className='text-xs md:text-lg'>Rodina</p></button>
                  <button onClick={() => handleCategoryChange('tehotenske')} className={`btn-tehotenske ${selectedCategory === 'tehotenske' ? 'active' : ''}`}><p className='text-xs md:text-lg'>Těhotenské</p></button>
                  <button onClick={() => handleCategoryChange('newborn')} className={`btn-newborn ${selectedCategory === 'newborn' ? 'active' : ''}`}><p className='text-xs md:text-lg'>Newborn</p></button>
                  <button onClick={() => handleCategoryChange('pary')} className={`btn-pary ${selectedCategory === 'pary' ? 'active' : ''}`}><p className='text-xs md:text-lg'>Páry</p></button>
                </div>
                <div className='py-4' id='filtered'>
                  <GalleryComponent images={filteredImages} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Galerie;
