import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import EmblaDots from '../components/EmblaDots'; // Adjust the path as necessary
import { FiFacebook, FiInstagram, FiMail, FiPhoneCall } from 'react-icons/fi'


const Uvod = () => {
  const [emblaRefDesktop, emblaApiDesktop] = useEmblaCarousel(
    { loop: true },
    [Autoplay({ delay: 3000 })]
  );

  const [emblaRefMobile, emblaApiMobile] = useEmblaCarousel(
    { loop: true },
    [Autoplay({ delay: 3000 })]
  );

  useEffect(() => {
    if (emblaApiDesktop) console.log(emblaApiDesktop.slideNodes());
  }, [emblaApiDesktop]);

  useEffect(() => {
    if (emblaApiMobile) console.log(emblaApiMobile.slideNodes());
  }, [emblaApiMobile]);

  return (
    <>
      <Helmet>
        <title>Kavalier Photographer</title>
        <meta name="description" content="Rodinná a reportážní fotografka z Českého Ráje. Nefotím nucené pózy, ale čisté emoce, radost, lásku a rodinnou pohodu." />
      </Helmet>

      <div className='w-full h-screen'>
        <div className='h-full -mt-[80px]'>
          <div className="hidden md:block h-full embla" ref={emblaRefDesktop}>
            <div className="h-full embla__container">
              <div className="embla__slide slide-desktop">
                <img src="./assets/gallery/rodina/rodina%20(5).webp" alt="Kavalier Photography" />
              </div>
              <div className="embla__slide slide-desktop">
                <img src="./assets/gallery/tehotenske/tehotenske%20(6).webp" alt="Kavalier Photography" />
              </div>
              <div className="embla__slide slide-desktop">
                <img src="./assets/gallery/svatby/svatby%20(12).webp" alt="Kavalier Photography" />
              </div>
              <div className="embla__slide slide-desktop">
                <img src="./assets/gallery/rodina/rodina%20(3).webp" alt="Kavalier Photography" />
              </div>
            </div>
            <div className='container'>
              <EmblaDots emblaApi={emblaApiDesktop} />
            </div>
          </div>

          {/* mobile slider */}
          <div className="block md:hidden h-full embla" ref={emblaRefMobile}>
            <div className="h-full embla__container">
              <div className="embla__slide slide-mobile">
                <img src="./assets/gallery/svatby/svatby%20(11).webp" alt="Kavalier Photography" />
              </div>
              <div className="embla__slide slide-mobile">
                <img src="./assets/gallery/rodina/rodina%20(9).webp" alt="Kavalier Photography" />
              </div>
              <div className="embla__slide slide-mobile">
                <img src="./assets/gallery/tehotenske/tehotenske%20(5).webp" alt="Kavalier Photography" />
              </div>
              <div className="embla__slide slide-mobile">
                <img src="./assets/gallery/svatby/svatby%20(5).webp" alt="Kavalier Photography" />
              </div>
              <div className="embla__slide slide-mobile">
                <img src="./assets/gallery/rodina/rodina%20(15).webp" alt="Kavalier Photography" />
              </div>
            </div>
            <EmblaDots emblaApi={emblaApiMobile} />
          </div>

          <div className='w-full h-[150px] md:h-[50px] absolute bottom-0 bg-black/20'>
            <div className='w-full h-full' id='socials-wrap'>
              <div className="h-full container flex flex-col md:flex-row justify-between items-center  gap-5 md:gap-0">
                <div className='hidden md:block'>
                  <EmblaDots emblaApi={emblaApiDesktop} />
                </div>
                <div className='block md:hidden'>
                  <EmblaDots emblaApi={emblaApiMobile} />
                </div>
                <div className='flex justify-center gap-6 text-white text-lg'>
                  <a href="https://www.facebook.com/kavalierAd" target='_blank' rel='noreferrer'>
                    <div className='p-2 rounded hover:bg-slate-100 hover:text-black '><FiFacebook /></div>
                  </a>
                  <a href="https://www.instagram.com/kavalier__photographer/" target='_blank' rel='noreferrer'>
                    <div className='p-2 rounded hover:bg-slate-100 hover:text-black '><FiInstagram /></div>
                  </a>
                  <a href="mailto:adela.kavalirova@seznam.cz" target='_blank' rel='noreferrer'>
                    <div className='p-2 rounded hover:bg-slate-100 hover:text-black '><FiMail /></div>
                  </a>
                  <a href="tel:+420732613904" target='_blank' rel='noreferrer'>
                    <div className='p-2 rounded hover:bg-slate-100 hover:text-black '><FiPhoneCall /></div>
                  </a>
                </div>
                <div className='hidden md:block'>
                  <p className='uppercase text-sm text-slate-200'><span className='mr-1 font-extralight'>&copy;{new Date().getFullYear()}</span>Kavalier Photographer</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Uvod;
