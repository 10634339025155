import React, { useState } from 'react';

const ContactForm = ({ preSelectedService }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        service: preSelectedService || '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className="w-full">
            <form action="https://app.headlessforms.cloud/api/v1/form-submission/jX1JZYSVgI" method="POST">
                <div className="mb-8">
                    <label htmlFor="categorySelect" className="block font-medium mb-1">
                        Vyberte o jakou službu máte zájem
                    </label>
                    <select id="categorySelect" name="category" className="w-full p-2 border">
                        <option value="Rodinné focení">Rodinné focení</option>
                        <option value="Párové focení">Párové focení</option>
                        <option value="Těhotenské focení">Těhotenské focení</option>
                        <option value="Svatební focení">Svatební focení</option>
                        <option value="Chci se jen informovat">Chci se jen informovat</option>
                    </select>
                </div>

                <div className="mb-8">
                    <label htmlFor="name" className="block font-medium mb-1">
                        Vaše jméno
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder='Vaše jméno'
                        className='w-full p-2 border'
                        required
                    />
                </div>
                <div className="mb-8">
                    <label htmlFor="email" className="block font-medium mb-1">
                        Emailová adresa
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder='email@seznam.cz'
                        className='w-full p-2 border'
                        required
                    />
                </div>
                <div className="mb-8">
                    <label htmlFor="message" className="block font-medium mb-1">
                        Prostor pro Vaší zprávu
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows="4"
                        placeholder='Mám zájem o rodinné focení'
                        className='w-full p-2 border'
                        required
                    ></textarea>
                </div>
                <button
                    type="submit"
                    className="btn-primary"
                >
                    Odeslat
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
