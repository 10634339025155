// react + dependencies
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
// components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// pages
import Uvod from "./pages/Uvod";
import Kontakt from "./pages/Kontakt";
import Galerie from "./pages/Galerie";
import Cenik from "./pages/Cenik";
import Svatby from "./pages/Svatby";
import About from "./pages/About";

const tagManagerArgs = {
  gtmId: "GTM-P4F6DJ6B",
};

TagManager.initialize(tagManagerArgs);

function App() {
  const location = useLocation();

  // Determine if the footer should be displayed based on the current route
  const shouldDisplayFooter = location.pathname !== '/';

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Uvod />} />
        <Route path="/o-mne" element={<About />} />
        <Route path="/galerie" element={<Galerie />} />
        <Route path="/svatby" element={<Svatby />} />
        {/* 
        <Route path="/cenik" element={<Cenik />} />
        */}
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {/* exclude footer from location determined above */}
      {shouldDisplayFooter && <Footer />}
    </>
  );
}

export default App;
