const images = [
    // obecna galerie
    // kategorie: newborn, pary, rodina, tehotenske

    // newborn
    // {
    //    url: '../assets/gallery/newborn/newborn (1).webp',
    //    category: 'newborn'
    //},
    {
        url: '../assets/gallery/newborn/newborn (1).webp',
        category: 'newborn'
    },
    {
        url: '../assets/gallery/newborn/newborn (2).webp',
        category: 'newborn'
    },
    {
        url: '../assets/gallery/newborn/newborn (3).webp',
        category: 'newborn'
    },
    {
        url: '../assets/gallery/newborn/newborn (4).webp',
        category: 'newborn'
    },
    {
        url: '../assets/gallery/newborn/newborn (5).webp',
        category: 'newborn'
    },

    // pary
    {
        url: '../assets/gallery/pary/pary (1).webp',
        category: 'pary'
    },
    {
        url: '../assets/gallery/pary/pary (2).webp',
        category: 'pary'
    },
    {
        url: '../assets/gallery/pary/pary (3).webp',
        category: 'pary'
    },

    // rodina
    {
        url: '../assets/gallery/rodina/rodina (1).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (2).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (3).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (4).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (5).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (6).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (7).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (8).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (9).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (10).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (11).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (12).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (13).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (14).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (15).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (16).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (17).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (18).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (19).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (20).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (21).webp',
        category: 'rodina'
    },
    {
        url: '../assets/gallery/rodina/rodina (22).webp',
        category: 'rodina'
    },

    // tehotenske
    {
        url: '../assets/gallery/tehotenske/tehotenske (1).webp',
        category: 'tehotenske'
    },
    {
        url: '../assets/gallery/tehotenske/tehotenske (2).webp',
        category: 'tehotenske'
    },
    {
        url: '../assets/gallery/tehotenske/tehotenske (3).webp',
        category: 'tehotenske'
    },
    {
        url: '../assets/gallery/tehotenske/tehotenske (4).webp',
        category: 'tehotenske'
    },
    {
        url: '../assets/gallery/tehotenske/tehotenske (5).webp',
        category: 'tehotenske'
    },
    {
        url: '../assets/gallery/tehotenske/tehotenske (6).webp',
        category: 'tehotenske'
    },
    {
        url: '../assets/gallery/tehotenske/tehotenske (7).webp',
        category: 'tehotenske'
    },

    // svatebni galerie
    // kategorie: svatby
    {
        url: '../assets/gallery/svatby/svatby (11).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (12).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (13).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (14).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (15).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (1).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (2).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (3).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (4).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (5).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (6).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (7).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (8).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (9).webp',
        category: 'svatby'
    },
    {
        url: '../assets/gallery/svatby/svatby (10).webp',
        category: 'svatby'
    },
];

export default images;