import React, { useEffect, useState } from 'react';
import { FiAlignRight, FiFacebook, FiInstagram, FiMail, FiPhoneCall } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [rightAnimation, setRightAnimation] = useState('animate__slideInUp');
  const [leftAnimation, setLeftAnimation] = useState('animate__slideInDown');
  const [isRotated, setIsRotated] = useState(false);

  const toggleVisibility = () => {
    if (isVisible) {
      setRightAnimation('animate__slideOutDown');
      setLeftAnimation('animate__slideOutUp');
      setTimeout(() => {
        setIsVisible(false);
        setIsRotated(false); // Reset rotation when menu is hidden
      }, 900); // animation delay
    } else {
      setIsVisible(true);
      setRightAnimation('animate__slideInUp');
      setLeftAnimation('animate__slideInDown');
      setIsRotated(true); // Rotate when menu is shown
    }
  };

  // Apply overflow:hidden to the body when the menu is visible
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isVisible]);

  return (
    <>
      <header className='w-full h-[80px] bg-white relative z-30'>
        <nav className='w-full h-full'>
          <div className='h-full container flex items-center justify-between'>
            <div className='relative z-50 mix-blend-difference text-black' id='logo'> {/* mix-blend-difference */}
              <NavLink to="/">
                <h1 className='uppercase text-white text-sm md:text-lg'>kavalier photographer</h1>
              </NavLink>
            </div>
            <div className='relative z-50 text-black'>
              <button
                className={`p-2 text-2xl bg-white rounded-full transform transition-transform ${isRotated ? 'rotate-180' : ''}`}
                onClick={toggleVisibility}
              >
                <FiAlignRight />
              </button>            </div>
          </div>

          {/* toggled navigation starts */}
          <div>
            {isVisible &&
              <div className="absolute z-40 top-0 left-0 h-screen w-full overflow-hidden" id='toggled-menu'>
                <div className='h-full flex flex-row items-center justify-center'>

                  {/* left section */}
                  <div className={`h-full w-5/12 md:w-5/12 animate__animated ${leftAnimation} bg-[var(--cerna)] md:bg-inherit`} id='left'>
                    <div className='w-full h-full flex flex-col items-center justify-center'>
                    </div>
                  </div>

                  {/* right section */}
                  <div className={`h-full w-7/12 md:w-7/12 bg-white animate__animated ${rightAnimation}`} id='right'>
                    <div className='w-full h-full flex flex-col items-center justify-center relative z-40'>
                      <div className='container flex flex-col items-center justify-start' id='menu-wrap'>
                        <div className='flex flex-col gap-5 uppercase' id='menu'>
                          <div className='group transition duration-300 w-fit'>
                            <NavLink to="/" onClick={toggleVisibility}>
                              domů
                              <span className="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-slate-400"></span>
                            </NavLink>
                          </div>
                          <div className='group transition duration-300 w-fit'>
                            <NavLink to="/galerie" onClick={toggleVisibility}>
                              galerie
                              <span className="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-slate-400"></span>
                            </NavLink>
                          </div>
                          <div className='group transition duration-300 w-fit'>
                            <NavLink to="/svatby" onClick={toggleVisibility}>
                              svatby
                              <span className="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-slate-400"></span>
                            </NavLink>
                          </div>
                          {/* 
                          <div className='group transition duration-300 w-fit'>
                            <NavLink to="/cenik" onClick={toggleVisibility}>
                              ceník
                              <span className="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-slate-400"></span>
                            </NavLink>
                          </div>
                          */}
                          <div className='group transition duration-300 w-fit'>
                            <NavLink to="/o-mne" onClick={toggleVisibility}>
                              o mně
                              <span className="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-slate-400"></span>
                            </NavLink>
                          </div>
                          <div className='group transition duration-300 w-fit'>
                            <NavLink to="/kontakt" onClick={toggleVisibility}>
                              kontakt
                              <span className="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-slate-400"></span>
                            </NavLink>
                          </div>

                          {/* socials links - hidden now */}
                          <div className='hidden flex-wrap items-center gap-5' id='socials-wrap'> {/* flex */}
                            <a href="https://www.facebook.com/kavalierAd" target='_blank' rel='noreferrer'>
                              <div className='p-2 text-xl rounded hover:bg-slate-200 '><FiFacebook /></div>
                            </a>
                            <a href="https://www.instagram.com/kavalier__photographer/" target='_blank' rel='noreferrer'>
                              <div className='p-2 text-xl rounded hover:bg-slate-200 '><FiInstagram /></div>
                            </a>
                            <a href="mailto:adela.kavalirova@seznam.cz" target='_blank' rel='noreferrer'>
                              <div className='p-2 text-xl rounded hover:bg-slate-100 '><FiMail /></div>
                            </a>
                            <a href="tel:+420732613904" target='_blank' rel='noreferrer'>
                              <div className='p-2 text-xl rounded hover:bg-slate-200 '><FiPhoneCall /></div>
                            </a>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          {/* toggled navigation ends */}


        </nav>
      </header>
    </>
  );
};

export default Navbar;
