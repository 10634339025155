// react + dependencies
import React from 'react'
import { Helmet } from 'react-helmet'
import ContactForm from '../components/ContactForm'
import { FiFacebook, FiInstagram, FiMail, FiPhoneCall } from 'react-icons/fi';

const Kontakt = () => {
  return (
    <>
      <Helmet>
        <title>Kavalier Photographer - Kontakt</title>
        <meta name="description" content="Rodinná a reportážní fotografka z Českého Ráje. Nefotím nucené pózy, ale čisté emoce, radost, lásku a rodinnou pohodu." />
      </Helmet>

      <section className='w-full my-0 md:my-10'>
        <div className={`w-full`}>
          <div className='h-full container flex flex-col md:flex-row items-start justify-between'>

            {/* left */}
            <div className='w-full md:w-4/12 relative md:sticky top-0 pt-6'>
              <div className='pl-0 mr-0 md:mr-10 pt-6 md:pt-10 border-t border-[var(--cerna)]'>
                <div>
                  <h3 className="text-3xl font-bold mb-4">Kontakt</h3>
                  <p>Pro kontaktování buď použijte kontaktní formulář nebo sociální sítě či přímý email přes odkazy níže.</p>
                </div>
                <div className='w-fit mt-4' id='socials-wrap'>
                  <div className='flex items-center gap-5' id='socials-wrap'>
                    <a href="https://www.facebook.com/kavalierAd" target='_blank' rel='noreferrer'>
                      <div className='p-2 text-xl rounded hover:bg-slate-200 '><FiFacebook /></div>
                    </a>
                    <a href="https://www.instagram.com/kavalier__photographer/" target='_blank' rel='noreferrer'>
                      <div className='p-2 text-xl rounded hover:bg-slate-200 '><FiInstagram /></div>
                    </a>
                    <a href="mailto:adela.kavalirova@seznam.cz" target='_blank' rel='noreferrer'>
                      <div className='p-2 text-xl rounded hover:bg-slate-100 '><FiMail /></div>
                    </a>
                    <a href="tel:+420732613904" target='_blank' rel='noreferrer'>
                      <div className='p-2 text-xl rounded hover:bg-slate-200 '><FiPhoneCall /></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* right */}
            <div className="w-full md:w-8/12 mt-6 md:mt-0 mb-20">
              <div className='w-full md:w-10/12 m-auto'>
                <ContactForm />
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Kontakt;
