// react + dependencies
import React from 'react'
import { Helmet } from 'react-helmet'

const About = () => {
    return (
        <>
            <Helmet>
                <title>Kavalier Photographer - O mně</title>
                <meta name="description" content="Rodinná a reportážní fotografka z Českého Ráje. Nefotím nucené pózy, ale čisté emoce, radost, lásku a rodinnou pohodu." />
            </Helmet>

            <section className='w-full my-0 md:my-10'>
                <div className={`w-full`}>
                    <div className='h-full container flex flex-col md:flex-row items-start justify-between'>

                        {/* left */}
                        <div className='w-full md:w-4/12 relative md:sticky top-0 pt-6'>
                            <div className='pl-0 mr-0 md:mr-10 pt-6 md:pt-10 border-t border-[var(--cerna)]'>
                                <div>
                                    <h3 className="text-3xl font-bold mb-4">O mně</h3>
                                    <p className='font-semibold mb-2'>Dobrý den, ahoj! Jsem Adéla. </p>
                                    <p>Maminka úžasného chlapečka, horlivá cestovatelka, sportovní fanoušek a nadšená fotografka. Duší jsem typická blondýna, která neví, kam si dala klíče od auta a která mluví rychleji než myslí. Focení se věnuji už 10 let. Fotím v exteriéru a na přání i v interiéru. Baví mě rozmanitost přírody a přirozeného světla. Kdo se nebojí přivstat, můžeme spolu zajít na ranní procházku plnou studené rosy nebo se naopak setkat při romantickém západu slunce.
                                        <br />
                                        Fotím rodiny s dětmi, budoucí rodiče, páry a příležitostně i svatby.
                                        <br />
                                        Ať už jste přišli náhodou nebo na doporučení, děkuji za to a těším se na společné setkání.</p>
                                </div>
                            </div>
                        </div>

                        {/* right */}
                        <div className="w-full md:w-8/12 mt-6 md:mt-0 mb-20">
                            <div className='w-full md:w-10/12 m-auto'>
                                <div>
                                    <img src="../assets/profile/adela.webp" alt="adela" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default About;
