import React from 'react';

const EmblaDots = ({ emblaApi }) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    React.useEffect(() => {
        if (!emblaApi) return;
        const onSelect = () => {
            setSelectedIndex(emblaApi.selectedScrollSnap());
        };
        emblaApi.on('select', onSelect);
        return () => emblaApi && emblaApi.off('select', onSelect);
    }, [emblaApi]);

    const dots = emblaApi ? emblaApi.scrollSnapList().map((_, index) => (
        <button
            key={index}
            className={`embla-dot${selectedIndex === index ? ' is-selected' : ''}`}
            onClick={() => emblaApi.scrollTo(index)}
        />
    )) : null;

    return (
        <div className="embla-dots">{dots}</div>
    );
};

export default EmblaDots;
