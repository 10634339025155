// react + dependencies
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
// icons
import { FiChevronLeft, FiChevronRight, FiX } from 'react-icons/fi';
// SEO plugin
import { Helmet } from 'react-helmet';
// api
import images from '../api/galleryImages';
// masonry gallery
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

const Svatby = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    setFilteredImages(images.filter(image => image.category === 'svatby'));
  }, []);

  const nextImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === filteredImages.length - 1 ? 0 : prevIndex + 1
    );
  }, [filteredImages]);

  const prevImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? filteredImages.length - 1 : prevIndex - 1
    );
  }, [filteredImages]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (lightboxOpen && event.keyCode === 27) {
        // ESC key
        closeLightbox();
      } else if (lightboxOpen && event.keyCode === 37) {
        // Left arrow key
        prevImage();
      } else if (lightboxOpen && event.keyCode === 39) {
        // Right arrow key
        nextImage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [lightboxOpen, nextImage, prevImage]);

  const openLightbox = (index) => {
    setSelectedImageIndex(index);
    setLightboxOpen(true);
    if (window.innerWidth > 768) { // Adjust the breakpoint as needed
      document.body.style.overflow = 'hidden'; // Disable scrolling on larger devices
    }
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    if (window.innerWidth > 768) { // Adjust the breakpoint as needed
      document.body.style.overflow = ''; // Enable scrolling on larger devices
    }
  };


  return (
    <>
      <Helmet>
        <title>Kavalier Photographer - Svatby</title>
        <meta name="description" content="Rodinná a reportážní fotografka z Českého Ráje. Nefotím nucené pózy, ale čisté emoce, radost, lásku a rodinnou pohodu." />
      </Helmet>

      <section className='w-full my-0 md:my-10'>
        <div className='w-full'>
          <div className='h-full container flex flex-col md:flex-row items-start justify-between'>
            {/* left */}
            <div className='w-full md:w-4/12 relative md:sticky top-0 pt-0 md:pt-6'>
              <div className='pl-0 mr-0 md:mr-10 pt-6 md:pt-10 border-t border-[var(--cerna)]'>
                <div>
                  <h3 className="text-3xl font-bold mb-4">Svatby</h3>
                  <p>V případě zájmu o svatební focení mě prosím kontaktujte pomocí kontaktního formuláře.</p>
                </div>
                <div className='mt-4'>
                  <NavLink to="/kontakt">
                    <button className='btn-primary'>napište mi</button>
                  </NavLink>
                </div>
              </div>
            </div>

            {/* right */}
            <div className="w-full md:w-8/12 mt-6 md:mt-0 mb-20">
              <div className='w-full md:w-10/12 m-auto'>
                <div id='gallery-component-wrap'>

                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  >
                    <Masonry>
                      {filteredImages.map((image, index) => (
                        <div className='p-1' key={index} onClick={() => openLightbox(index)}>
                          <img src={image.url} alt={image.alt} className="w-full h-auto hover:opacity-75" loading='lazy' style={{ cursor: 'pointer' }} />
                        </div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>

                  {/* lightbox - currently hidden on mobile devices */}
                  {lightboxOpen && (
                    <div className='hidden md:block' style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 999 }} id='lightbox'>
                      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <img src={filteredImages[selectedImageIndex].url} alt={filteredImages[selectedImageIndex].alt} style={{ maxWidth: '90vw', maxHeight: '90vh' }} className='border-2 border-[var(--cerna)]' />
                        <button className='absolute z-50 top-0 right-0 -mr-8 border border-t-2 border-l-2 border-[var(--cerna)] text-[var(--cerna)] bg-white p-2 text-xs' onClick={closeLightbox}><FiX /></button>
                        <div className='absolute bottom-0 w-full h-full flex items-center justify-between gap-2 mt-2'>
                          <button className='-ml-8 border border-t-2 border-l-2 border-[var(--cerna)] text-[var(--cerna)] bg-white p-2 text-xs' onClick={prevImage}><FiChevronLeft /></button>
                          <button className='-mr-8 border border-t-2 border-l-2 border-[var(--cerna)] text-[var(--cerna)] bg-white p-2 text-xs' onClick={nextImage}><FiChevronRight /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Svatby;
