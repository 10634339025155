// react + dependencies
import React from 'react'
import { FiFacebook, FiInstagram, FiMail, FiPhoneCall } from 'react-icons/fi'

const Footer = () => {
  return (
    <footer className='w-full fixed bottom-0 bg-white'>
      <div className='py-0 md:py-6 container'>
        <div className='flex flex-row items-center justify-between flex-wrap'>
          <div className='w-4/12 md:w-9/12'>
            <p className='uppercase text-sm'><span className='mr-1 font-extralight'>&copy;{new Date().getFullYear()}</span>Kavalier Photographer</p>
          </div>
          <div className='w-fit' id='socials-wrap'>
            <div className='flex justify-end gap-2'>
              <a href="https://www.facebook.com/kavalierAd" target='_blank' rel='noreferrer'>
                <div className='p-2 rounded hover:bg-slate-100 '><FiFacebook /></div>
              </a>
              <a href="https://www.instagram.com/kavalier__photographer/" target='_blank' rel='noreferrer'>
                <div className='p-2 rounded hover:bg-slate-100 '><FiInstagram /></div>
              </a>
              <a href="mailto:adela.kavalirova@seznam.cz" target='_blank' rel='noreferrer'>
                <div className='p-2 rounded hover:bg-slate-100 '><FiMail /></div>
              </a>
              <a href="tel:+420732613904" target='_blank' rel='noreferrer'>
                <div className='p-2 rounded hover:bg-slate-100 '><FiPhoneCall /></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer