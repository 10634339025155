// react + dependencies
import React, { useCallback, useEffect, useState } from 'react';
// icons
import { FiChevronLeft, FiChevronRight, FiX } from 'react-icons/fi';
// masonry gallery
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

const Gallery = ({ images }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openLightbox = (index) => {
    setSelectedImageIndex(index);
    setLightboxOpen(true);
    if (window.innerWidth > 768) { // Adjust the breakpoint as needed
      document.body.style.overflow = 'hidden'; // Disable scrolling on larger devices
    }
  };
  
  const closeLightbox = () => {
    setLightboxOpen(false);
    if (window.innerWidth > 768) { // Adjust the breakpoint as needed
      document.body.style.overflow = ''; // Enable scrolling on larger devices
    }
  };
    // next image function
  const nextImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  }, [images]);
  // previous image function
  const prevImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  }, [images]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (lightboxOpen && event.keyCode === 27) {
        // ESC key
        closeLightbox();
      } else if (lightboxOpen && event.keyCode === 37) {
        // Left arrow key
        prevImage();
      } else if (lightboxOpen && event.keyCode === 39) {
        // Right arrow key
        nextImage();
      }
    };


    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [lightboxOpen, nextImage, prevImage]);

  return (
    <div id='gallery-component-wrap'>

      {/* gallery items */}

      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
      >
        <Masonry>
          {images.map((image, index) => (
            <div className='p-1' key={index} onClick={() => openLightbox(index)}>
              <img src={image.url} alt={image.alt} className="w-full h-auto hover:opacity-75" loading='lazy' style={{ cursor: 'pointer' }} />
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>

      {/* hidden and block - lightbox is hidden on mobile devices */}
      {lightboxOpen && (
        <div className='hidden md:block' style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 999 }} id='lightbox'>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <img src={images[selectedImageIndex].url} alt={images[selectedImageIndex].alt} style={{ maxWidth: '90vw', maxHeight: '90vh' }} className='border-2 border-[var(--cerna)]' />
            <button className='absolute z-50 top-0 right-0 -mr-8 border border-t-2 border-l-2 border-[var(--cerna)] text-[var(--cerna)] bg-white p-2 text-xs' onClick={closeLightbox}><FiX /></button>
            <div className='absolute bottom-0 w-full h-full flex items-center justify-between gap-2 mt-2'>
              <button className='-ml-8 border border-t-2 border-l-2 border-[var(--cerna)] text-[var(--cerna)] bg-white p-2 text-xs' onClick={prevImage}><FiChevronLeft /></button>
              <button className='-mr-8 border border-t-2 border-l-2 border-[var(--cerna)] text-[var(--cerna)] bg-white p-2 text-xs' onClick={nextImage}><FiChevronRight /></button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Gallery;
